/**
 * @file index.jsx is the root file for react-router-dom
 * @description This file contains all the routes of the application
 * @requires react-router-dom
 * @requires react
 *
 */
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import globalRequest from "global-modules/globalRequest";
import { API } from "studentEnum";
import {useDispatch, useSelector} from 'react-redux';
import {currentMaintanance, changeMaintanance, changeMaintanancePassword} from '../redux/reducers/maintanance';

const SignUp = React.lazy(() => import("pages/Onboarding/SignUp"));
const Login = React.lazy(() => import("pages/Onboarding/Login"));
const LoginStudent = React.lazy(() => import("pages/Onboarding/LoginStudent"));
// const CreateNewPassword = React.lazy(() => import("pages/Onboarding/CreateNewPassword"));
const ResetPassword = React.lazy(() => import("pages/Onboarding/ResetPassword"));
const ForgotStudentID = React.lazy(() => import("pages/Onboarding/ForgotStudentID"));
const ForgotPasswordStudent = React.lazy(() => import("pages/Onboarding/ForgotPasswordStudent"));
const TutorSignUp = React.lazy(() => import("pages/Onboarding/TutorSignUp"));
const StudentSignUp = React.lazy(() => import("pages/Onboarding/StudentSignUp"));
const GoogleSignupStudent = React.lazy(() => import("pages/Onboarding/GoogleSignupStudent"));
const StudentSignUpStepTwo = React.lazy(() => import("pages/Onboarding/StudentSignUpStepTwo"));
const StudentVerification = React.lazy(() => import("pages/Onboarding/StudentVerification"));
const StudentSetPassword = React.lazy(() => import("pages/Onboarding/StudentSetPassword"));
const StudentTutorSetPassword = React.lazy(() => import("pages/Student/StudentTutorSetPassword"));
const Verification = React.lazy(() => import("pages/Onboarding/Verification"));
const TutorWhyDidYouStudy = React.lazy(() => import("pages/Onboarding/TutorWhyDidYouStudy"));
const TutorSelectLanguage = React.lazy(() => import("pages/Onboarding/TutorSelectLanguage"));
const TutorAddSubject = React.lazy(() => import("pages/Onboarding/TutorAddSubject"));
const TutorUploadDocument = React.lazy(() => import("pages/Onboarding/TutorUploadDocument"));
const TutorSignupThankyou = React.lazy(() => import("pages/Onboarding/TutorSignupThankyou"));
const TutorUnderReview = React.lazy(() => import("pages/Onboarding/TutorUnderReview"));
const TutorSetPassword = React.lazy(() => import("pages/Onboarding/TutorSetPassword"));
const TutorPayoutReport = React.lazy(() => import("pages/Tutor/TutorPayoutReport"));
const TutorPayoutPeriod = React.lazy(() => import("pages/Tutor/TutorPayoutPeriod"));
const TutorPayoutDetail = React.lazy(() => import("pages/Tutor/TutorPayoutDetail"));
const TutorPayoutDetailOld = React.lazy(() => import("pages/Tutor/TutorPayoutDetailOld"));
const TutorEmptyDetail = React.lazy(() => import("pages/Tutor/TutorEmptyDetail"));
const TutorMyTip = React.lazy(() => import("pages/Tutor/TutorMyTip"));
const TutorCalender = React.lazy(() => import("pages/Tutor/TutorCalender"));
const TutorSetAvailability = React.lazy(() => import("pages/Tutor/TutorSetAvailability"));
const TutorHandbook = React.lazy(() => import("pages/Tutor/TutorHandbook"));
const TutorManageSession = React.lazy(() => import("pages/Tutor/TutorManageSession"));
const TutorialDetail = React.lazy(() => import("pages/Tutor/TutorialDetail"));
const TutorialDetailOne = React.lazy(() => import("pages/Tutor/TutorialDetailOne"));
const TutorialDetailTwo = React.lazy(() => import("pages/Tutor/TutorialDetailTwo"));
const TutorialDetailThird = React.lazy(() => import("pages/Tutor/TutorialDetailThird"));
const TutorProfile = React.lazy(() => import("pages/Tutor/TutorProfile"));
const VideoConference = React.lazy(() => import("pages/Tutor/VideoConference"));
const DepartmentWall = React.lazy(() => import("pages/Tutor/DepartmentWall"));
const TutorCreateLesson = React.lazy(() => import("pages/Tutor/TutorCreateLesson/CreateLessonOne"));
const TutorCreateLessonTwo = React.lazy(() => import("pages/Tutor/TutorCreateLesson/CreateLessonTwo"));
const TutorLessonPreview = React.lazy(() => import("pages/Tutor/TutorLessonPreview"));
// const TeacherProfile = React.lazy(() => import("pages/Student/TeacherProfile"));
const LeadTutorProfile = React.lazy(() => import("pages/Student/LeadTutorProfile"));
const FindTutor = React.lazy(() => import("pages/Student/FindTutor"));
const StudentDepartmentWall = React.lazy(() => import("pages/Student/StudentDepartmentWall"));
// const SeriesDetail = React.lazy(() => import("pages/Student/SeriesDetail"));
const LessonDetail = React.lazy(() => import("pages/Student/LessonDetail"));
// const RecordedLessonDetail = React.lazy(() => import("pages/Student/LessonDetail/RecordedLessonDetail"));
const Congratulations = React.lazy(() => import("pages/Student/Congratulations"));
const ManageSessions = React.lazy(() => import("pages/Student/ManageSessions"));
const ManageSessionDetail = React.lazy(() => import("pages/Student/ManageSessionDetail"));
const ManageSessionTutorailDetail = React.lazy(() => import("pages/Student/ManageSessionDetail/TutorailDetail"));
const PurchasePlan = React.lazy(() => import("pages/Student/PurchasePlan"));
const MySubscription = React.lazy(() => import("pages/Student/MySubscription"));
const PayInvoice = React.lazy(() => import("pages/Student/PayInvoice"));
const MyCredit = React.lazy(() => import("pages/Student/MyCredit"));
const StudentMyTip = React.lazy(() => import("pages/Student/StudentMyTip"));
const NotFound = React.lazy(() => import("pages/NotFound"));
const ExitSession = React.lazy(() => import("pages/ExitSession"));
const StudentProfile = React.lazy(() => import("pages/Student/StudentProfile"));
const PaymentPlanPurchase = React.lazy(() => import("pages/Student/PaymentPlanPurchase"));
const PaymentPlanPurchase1 = React.lazy(() => import("pages/Student/PaymentPlanPurchase1"));
const PaymentHourPurchase = React.lazy(() => import("pages/Student/PaymentHourPurchase"));
const PaymentDownUpPurchase = React.lazy(() => import("pages/Student/PaymentDownUpPurchase"));
const DashboardStudent = React.lazy(() => import("pages/Student/DashboardStudent"));
const IsLoggedin = React.lazy(() => import("./ProtectedRoutes/IsLoggedin"));
const TutorIsNotLoggedIn = React.lazy(() => import("./ProtectedRoutes/TutorIsNotLoggedIn"));
const StudentIsNotLoggedIn = React.lazy(() => import("./ProtectedRoutes/StudentIsNotLoggedIn"));
const TutorStepsCheck = React.lazy(() => import("./ProtectedRoutes/TutorStepsCheck"));
const HomePage = React.lazy(() => import("pages/Static/Homepage"));
const TutorReverseStepsCheck = React.lazy(() => import("./ProtectedRoutes/TutorReverseStepsCheck"));
const TermsCondition = React.lazy(() => import("pages/Static/Cms/TermsCondition"));
const PrivacyPolicy = React.lazy(() => import("pages/Static/Cms/PrivacyPolicy"));
const CookiePolicy = React.lazy(() => import("pages/Static/Cms/CookiePolicy"));
const ContractTutorServices = React.lazy(() => import("pages/Static/Cms/ContractTutorServices"));
const ContactUs = React.lazy(() => import("pages/Static/ContactUs"));
const AboutUs = React.lazy(() => import("pages/Static/AboutUs"));
const Faq = React.lazy(() => import("pages/Static/Cms/Faq"));
// const Splash = React.lazy(() => import("pages/Splash"));
const Maintanance = React.lazy(() => import("pages/Maintanance"));

const ProjectRoutes = () => {

  const dispatch = useDispatch();
  const currentMaintananceO = useSelector(currentMaintanance);

  let [isSplash, setIsSplash] = React.useState(false);
  
  const getMaintanaice = async () => {
    if (sessionStorage.getItem('isMaintanance')) return false;
    const response = await globalRequest('get', API.COMMON.GET_SYSTEM_MAINTENANCE, {}, {}, false, true);
    if(response?.data?.maintenance_mode == 1) {
      dispatch(changeMaintanance(true));
      dispatch(changeMaintanancePassword(response.data.maintenance_password));
    } else {
      dispatch(changeMaintanance(false));
    }
  }


  React.useEffect(() => {
    setTimeout(() => {
      setIsSplash(false);
    }, 8000);
  }, [isSplash]);

  React.useLayoutEffect(() => {
    getMaintanaice();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Suspense
      fallback={
        <div className="lds-ring"> <div></div> <div></div> <div></div> <div></div> </div>
      }
    >
      {/* {isSplash ? <Splash />: null} */}
      {currentMaintananceO ? <Maintanance /> : null}
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* onboarding page */}
          <Route path="/" element={<IsLoggedin />}>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login-tutor" element={<Login />} />
            <Route path="/login-student" element={<LoginStudent />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="forgot-studentId" element={<ForgotStudentID />} />
            <Route path="/forgot-password-stundent" element={<ForgotPasswordStudent />} />
            <Route path="/createnewpassword" element={<StudentTutorSetPassword />} />
            {/* tutor page */}
            <Route path="/tutorsignup" element={<TutorSignUp />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/set-password" element={<TutorSetPassword />} />
            {/* student page */}
            <Route path="/studentsignup" element={<StudentSignUp />} />
            <Route path="/google-signup-student" element={<GoogleSignupStudent />} />
            <Route path="/studentsignupsteptwo" element={<StudentSignUpStepTwo />} />
            <Route path="/student-verification" element={<StudentVerification />} />
            <Route path="/student-set-password" element={<StudentSetPassword />} />
          </Route>
          {/* tutor page */}
          <Route path="/" element={<TutorIsNotLoggedIn />}>
            <Route path="/" element={<TutorReverseStepsCheck />}>
              <Route path="/what-did-you-study" element={<TutorWhyDidYouStudy />} />
              <Route path="/select-language" element={<TutorSelectLanguage />} />
              <Route path="/add-subject" element={<TutorAddSubject />} />
              <Route path="/upload-document" element={<TutorUploadDocument />} />
            </Route>
            <Route path="/signup-thankyou" element={<TutorSignupThankyou />} />
            <Route path="/under-review" element={<TutorUnderReview />} />
            <Route path="/" element={<TutorStepsCheck />}>
              <Route path="/payout-report" element={<TutorPayoutReport />} />
              <Route path="/payout-period/:slug" element={<TutorPayoutPeriod />} />
              <Route path="/payout-detail" element={<TutorPayoutDetail />} />
              <Route path="/tutor-payout-detail" element={<TutorPayoutDetailOld />} />

              <Route path="/empty-detail" element={<TutorEmptyDetail />} />
              <Route path="/my-tips" element={<TutorMyTip />} />
              <Route path="/calender" element={<TutorCalender />} />
              <Route path="/my-availability" element={<TutorSetAvailability />} />
              <Route path="/manage-session" element={<TutorManageSession />} />
              <Route path="/tutorial-detail/:sessionId/:bookingSlug" element={<TutorialDetail />} />
              <Route path="/tutorial-detail/:sessionId" element={<TutorialDetail />} />
              <Route path="/tutorail-request-detail/:slug" element={<TutorialDetailOne />} />
              <Route path="/tutorial-detail-two/:sessionId" element={<TutorialDetailTwo />} />
              <Route path="/tutorial-detail-third" element={<TutorialDetailThird />} />
              <Route path="/tutor-profile" element={<TutorProfile />} />
              <Route path="/department-wall/:slug" element={<DepartmentWall />} />
              <Route path="/tutor-create-lesson" element={<TutorCreateLesson />} />
              <Route path="/tutor-create-lesson/:id" element={<TutorCreateLesson />} />
              <Route path="/tutor-create-lesson-two/:session_id" element={<TutorCreateLessonTwo />} />
              <Route path="/tutor-lesson-preview" element={<TutorLessonPreview />} />
              <Route path="/tutor-lesson-preview/:id" element={<TutorLessonPreview />} />
              <Route path="/video-conference" element={<VideoConference />} />
              <Route path="/tutor-handbook" element={<TutorHandbook />} />
            </Route>
          </Route>

          {/* student page */}
          <Route path="/purchase-plan" element={<PurchasePlan />} />
          {/* <Route path="/teacher-profile" element={<TeacherProfile />} /> */}
          <Route path="/tutor-detail/:slug" element={<LeadTutorProfile />} />
          <Route path="/find-tutor" element={<FindTutor />} />
          <Route path="/find-tutor/:slug" element={<FindTutor />} />
          <Route path="/series-detail/:slug" element={<LessonDetail />} />
          {/* <Route path="/recorded-lesson-detail" element={<RecordedLessonDetail />} /> */}
          
          <Route path="/student-department-wall/:slug" element={<StudentDepartmentWall />}/>
          
          <Route path="/" element={<StudentIsNotLoggedIn />}>
            {/* <Route path="/series-detail" element={<SeriesDetail />} /> */}
            <Route path="/congratulations" element={<Congratulations />} />
            <Route path="/manage-sessions" element={<ManageSessions />} />
            <Route path="/manage-session-detail/:slug" element={<ManageSessionDetail />} />
            <Route path="/manage-session-tutorial-detail/:slug" element={<ManageSessionTutorailDetail />} />
            <Route path="/my-subscription" element={<MySubscription />} />
            <Route path="/pay-invoice" element={<PayInvoice />} />
            <Route path="/my-credit" element={<MyCredit />} />
            <Route path="/student-my-tips" element={<StudentMyTip />} />
            <Route path="/student-profile" element={<StudentProfile />} />
            <Route path="/payment-plan-purchase" element={<PaymentPlanPurchase />} />
            <Route path="/payment-plan-purchase1" element={<PaymentPlanPurchase1 />} />
            <Route path="/payment-hour-purchase" element={<PaymentHourPurchase />} />
            <Route path="/payment-down-up-grade" element={<PaymentDownUpPurchase />} />
            <Route path="/dashboard-student" element={<DashboardStudent />} />
          </Route>
          {/* static pages */}
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/contract-tutor-services" element={<ContractTutorServices />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/exit-session" element={<ExitSession />} />

          <Route path="index.html"  element={<Navigate to="/" />} />
          {/* 404 page */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;